import React from "react";
import pageStyle from "../../assets/css/page.module.css";
import workStyle from "../../assets/css/work.module.css";
import style from "../../assets/css/main.module.css";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import HeroPage from "../../components/hero.page";
import Hamburger from "../../components/hamburger";


import imgHeader from "../../assets/img/realisations/ebquad_0.jpg";
import { Link } from "gatsby";
import SEO from "../../components/seo";

export default () => (
  <div id="top">
    <Menu />
    <SEO 
    title="EB QUAD | Agence digitale | MDS Digital Agency"
    description="Nous veillons à l’amélioration continue et à l’actualisation constante des informations présentes sur le site EB Quad."
    keywords="EB quad"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgHeader} />
    <div className={workStyle.info}>

      <div className={workStyle.info_cell}>
        <h3>Services</h3>
        <span className={workStyle.badge}><Link to="/services/website/">Création de site web</Link></span>
        <span className={workStyle.badge}><Link to="/services/referencement/">Référencement</Link></span>
        <span className={workStyle.badge}><Link to="/services/graphic-design/">Graphic design</Link></span>
        <span className={workStyle.badge}><Link to="/services/conseils/">Conseils</Link></span>


      </div>
      <div className={workStyle.info_cell}>
      <h3>Secteur</h3>
        <p>Sports moteur</p>
        <h3>Localisation</h3>
        <p>Givry</p>
      </div>

      <div className={workStyle.info_cell}>
        <h3>Lien </h3>
        <p>
          <a href="http://www.ebquad.be/" className={workStyle.external_link} target="_blank" rel="noopener noreferrer">ebquad.be</a>
        </p>
      </div>
    </div>

    <article className={pageStyle.article}>
      <h2>EB Quad</h2>
      <p>
      Ce spécialiste de la location, de la vente et de l’après-vente de quads et de buggys a confié à MDS <strong>la réalisation complète de son site internet</strong>.      
      </p>

      <p>
      MDS a conçu ce site en poursuivant un objectif double: offrir une <strong>expérience de qualité</strong> aux visiteurs du site et proposer à EB Quad <strong>des outils de mise à jour du site simples et efficaces</strong>.
      </p>
      <p>
      Ainsi, la société peut facilement actualiser son catalogue de véhicules neufs à vendre ou à louer, son offre de véhicules d’occasion, l’agenda illustré des randonnées et les galeries photos des randonnées précédentes.
      </p>
      <p>
      Nous participons à ces mises à jour à la demande de notre client et nous optimisons de manière régulière le référencement du site.
      </p>
      <p>
      Dans le cas de EB Quad, il s’agit de référencement naturel (SEO) mais également de campagnes de référencement payant. Nous gérons pour le compte d’EB Quad d’un budget media pour la publication d’annonces Adwords sur Google.
      </p>
      <div className={pageStyle.cta}>
       <Link to="/contact/">Contactez-nous pour en savoir plus</Link>
      </div>
    </article>
    <Footer />
  </div>
);
